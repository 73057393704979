import React from 'react';
import { useQueryParam } from 'src/hooks';
import Layout from 'src/layouts/Page';
import Seo from 'src/components/Seo';
import PlanForm from 'src/sections/Auth/Register/PlanForm';
import { theme } from 'src/theme';
import FaqSection, { FaqTypes } from 'src/sections/shared/Faq';
import PricingContactSection from 'src/sections/Pricing/Contact';
import ApiWrapper from 'src/components/ApiWrapper';

const CheckoutPage: React.FC = () => {
  const planId = useQueryParam('planId');
  return (
    <ApiWrapper apiServices={['auth-api', 'home-api', 'billing-api']}>
      <Layout
        navbarSpacer
        navbarProps={{
          backgroundCss: theme.palette.secondary.LIGHT_BLUE,
          darkText: true,
        }}
      >
        <Seo title="Sign Up - Bizwise" />
        <PlanForm />
        <FaqSection types={planId === 'free' ? [FaqTypes.PRICING, FaqTypes.FREE_FOREVER] : [FaqTypes.PRICING]} />
        <PricingContactSection />
      </Layout>
    </ApiWrapper>
  );
};

export default CheckoutPage;
