import React from 'react';
import PlanCard from 'src/components/PlanCard';
import { pxToRem } from 'src/theme';
import Typography from 'src/components/core/Typography';
import SectionContainer from '../../shared/SectionContainer';
import Form from './Form';
import { useCustomPlan } from './utils';

const PlanForm: React.FC = () => {
  const { customPlan, hasCoupon, showCouponApplied } = useCustomPlan();

  // TODO: if planDetails is not defined, render a skeleton of plan details component
  return (
    <SectionContainer background="light-blue">
      <div className="grid grid-cols-10 gap-10 md:gap-20 lg:gap-30 xl:gap-40 items-center justify-center">
        <div className="w-full flex justify-center md:justify-end order-2 md:order-1 col-span-10 md:col-span-5 lg:col-span-4">
          <div style={{ width: '100%', maxWidth: pxToRem(410) }}>
            <div className="flex items-center mb-8 md:mb-4">
              <hr className="md:hidden border border-blue border-dashed w-full" />
              <Typography
                variant="p1"
                color="blue"
                className="text-center md:text-left px-6 md:px-0"
                style={{ fontWeight: 600, whiteSpace: 'nowrap' }}
              >
                Plan Summary
              </Typography>
              <hr className="md:hidden border border-blue border-dashed w-full" />
            </div>
            {
              customPlan && (
                <PlanCard
                  data={customPlan}
                  hasCoupon={hasCoupon}
                  showCouponApplied={showCouponApplied}
                />
              )
            }
          </div>
        </div>
        <div
          className="order-1 md:order-2 col-span-10 md:col-span-5 lg:col-span-6 w-full justify-self-center md:justify-self-start"
          style={{ maxWidth: pxToRem(700) }}
        >
          <Form hasPlan />
        </div>
      </div>
    </SectionContainer>
  );
};

export default PlanForm;
